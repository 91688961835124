import { ReactComponent as microsoftteams } from "./microsoftteams.svg";
import { ReactComponent as microsoftteamsDark } from "./microsoftteamsDark.svg";
import { ReactComponent as microsoftteamsSmall } from "./microsoftteamsSmall.svg";
import { ReactComponent as arrowLeftCustom } from "./arrowLeftCustom.svg";
import { ReactComponent as arrowRightCustom } from "./arrowRightCustom.svg";
import { ReactComponent as remoteLogin } from "./remoteLogin.svg";
import { ReactComponent as unlock } from "./unlock.svg";
import { ReactComponent as webExSmall } from "./webExSmall.svg";
import { ReactComponent as webExDark } from "./webExDark.svg";
import { ReactComponent as workplaceSmall } from "./workplaceSmall.svg";
import { ReactComponent as linkedInSmall } from "./linkedInSmall.svg";
import { ReactComponent as computer } from "./computer.svg";
import { ReactComponent as instagrambusinessSmall } from "./instagrambusinessSmall.svg";
import { ReactComponent as twitterSmall } from "./twitterSmall.svg";
import { ReactComponent as facebookSmall } from "./facebookSmall.svg";
import { ReactComponent as star } from "./star.svg";
import { ReactComponent as verifyBadge } from "./verifyBadge.svg";
import { ReactComponent as userError } from "./userError.svg";
import { ReactComponent as transfer } from "./transfer.svg";
import { ReactComponent as revert } from "./revert.svg";
import { ReactComponent as notice } from "./notice.svg";
import { ReactComponent as liveHours } from "./liveHours.svg";
import { ReactComponent as hashtagError } from "./hashtagError.svg";
import { ReactComponent as feature } from "./feature.svg";
import { ReactComponent as deleteSign } from "./deleteSign.svg";
import { ReactComponent as confirmation } from "./confirmation.svg";
import { ReactComponent as arrowLeftLong } from "./arrowLeftLong.svg";
import { ReactComponent as locationPinDark } from "./locationPinDark.svg";
import { ReactComponent as locationPin } from "./locationPin.svg";
import { ReactComponent as dropdownIcon } from "./dropdownIcon.svg";
import { ReactComponent as filter } from "./filter.svg";
import { ReactComponent as longArrowDown } from "./longArrowDown.svg";
import { ReactComponent as refresh } from "./refresh.svg";
import { ReactComponent as customize } from "./customize.svg";
import { ReactComponent as team } from "./team.svg";
import { ReactComponent as arrowRightLarge } from "./arrowRightLarge.svg";
import { ReactComponent as editItem } from "./editItem.svg";
import { ReactComponent as arrowUpLarge } from "./arrowUpLarge.svg";
import { ReactComponent as radioOff } from "./radioOff.svg";
import { ReactComponent as radioOn } from "./radioOn.svg";
import { ReactComponent as afpColor } from "./afpColor.svg";
import { ReactComponent as afpWhite } from "./afpWhite.svg";
import { ReactComponent as reutersColor } from "./reutersColor.svg";
import { ReactComponent as reutersWhite } from "./reutersWhite.svg";
import { ReactComponent as skynewsarabiaWhite } from "./skynewsarabiaWhite.svg";
import { ReactComponent as url } from "./url.svg";
import { ReactComponent as mail } from "./mail.svg";
import { ReactComponent as stats } from "./stats.svg";
import { ReactComponent as atSign } from "./atSign.svg";
import { ReactComponent as autoApprove } from "./autoApprove.svg";
import { ReactComponent as eye } from "./eye.svg";
import { ReactComponent as hashtag } from "./hashtag.svg";
import { ReactComponent as key } from "./key.svg";
import { ReactComponent as leads } from "./leads.svg";
import { ReactComponent as manualApprove } from "./manualApprove.svg";
import { ReactComponent as onboard } from "./onboard.svg";
import { ReactComponent as profile } from "./profile.svg";
import { ReactComponent as users } from "./users.svg";
import { ReactComponent as appSpace } from "./appSpace.svg";
import { ReactComponent as edit } from "./edit.svg";
import { ReactComponent as error } from "./error.svg";
import { ReactComponent as google } from "./google.svg";
import { ReactComponent as workplaceDark } from "./workplaceDark.svg";
import { ReactComponent as workplace } from "./workplace.svg";
import { ReactComponent as webEx } from "./webEx.svg";
import { ReactComponent as twitter } from "./twitter.svg";
import { ReactComponent as linkedIn } from "./linkedIn.svg";
import { ReactComponent as instagrambusiness } from "./instagrambusiness.svg";
import { ReactComponent as facebook } from "./facebook.svg";
import { ReactComponent as changeColorRectangular } from "./changeColorRectangular.svg";
import { ReactComponent as changeColor } from "./changeColor.svg";
import { ReactComponent as screen } from "./screen.svg";
import { ReactComponent as deleteIcon } from "./delete.svg";
import { ReactComponent as facebookDark } from "./facebookDark.svg";
import { ReactComponent as twitterDark } from "./twitterDark.svg";
import { ReactComponent as instagrambusinessDark } from "./instagrambusinessDark.svg";
import { ReactComponent as linkedInDark } from "./linkedInDark.svg";
import { ReactComponent as empty } from "./empty.svg";
import { ReactComponent as billingOverview } from "./billingOverview.svg";
import { ReactComponent as checkGreen } from "./checkGreen.svg";
import { ReactComponent as checkBlue } from "./checkBlue.svg";
import { ReactComponent as play } from "./play.svg";
import { ReactComponent as menu } from "./menu.svg";
import { ReactComponent as longArrowLeft } from "./longArrowLeft.svg";
import { ReactComponent as longArrowRight } from "./longArrowRight.svg";
import { ReactComponent as createChannel } from "./createChannel.svg";
import { ReactComponent as add } from "./add.svg";
import { ReactComponent as addDark } from "./addDark.svg";
import { ReactComponent as archive } from "./archive.svg";
import { ReactComponent as arrowDown } from "./arrow-down.svg";
import { ReactComponent as arrowLeft } from "./arrow-left.svg";
import { ReactComponent as arrowRight } from "./arrow-right.svg";
import { ReactComponent as arrowUp } from "./arrow-up.svg";
import { ReactComponent as bell } from "./bell.svg";
import { ReactComponent as calendar } from "./calendar.svg";
import { ReactComponent as card } from "./card.svg";
import { ReactComponent as check } from "./check.svg";
import { ReactComponent as cross } from "./cross.svg";
import { ReactComponent as drag } from "./drag.svg";
import { ReactComponent as euro } from "./euro.svg";
import { ReactComponent as family } from "./family.svg";
import { ReactComponent as female } from "./female.svg";
import { ReactComponent as followUp } from "./follow-up.svg";
import { ReactComponent as graph } from "./graph.svg";
import { ReactComponent as gridOn } from "./grid_on.svg";
import { ReactComponent as groupActions } from "./group-actions.svg";
import { ReactComponent as heartCalendar } from "./heart-calendar.svg";
import { ReactComponent as heartTime } from "./heart-time.svg";
import { ReactComponent as help } from "./help.svg";
import { ReactComponent as home } from "./home.svg";
import { ReactComponent as hospital } from "./hospital.svg";
import { ReactComponent as label } from "./label.svg";
import { ReactComponent as loading } from "./loading.svg";
import { ReactComponent as male } from "./male.svg";
import { ReactComponent as man } from "./man.svg";
import { ReactComponent as medicalCheck } from "./medical-check.svg";
import { ReactComponent as messages } from "./messages.svg";
import { ReactComponent as nurse } from "./nurse.svg";
import { ReactComponent as ok } from "./ok.svg";
import { ReactComponent as phone } from "./phone.svg";
import { ReactComponent as picture } from "./picture.svg";
import { ReactComponent as pills } from "./pills.svg";
import { ReactComponent as reply } from "./reply.svg";
import { ReactComponent as room } from "./room.svg";
import { ReactComponent as save } from "./save.svg";
import { ReactComponent as search } from "./search.svg";
import { ReactComponent as settings } from "./settings.svg";
import { ReactComponent as sheets } from "./sheets.svg";
import { ReactComponent as syncClouds } from "./sync-clouds.svg";
import { ReactComponent as sync } from "./sync.svg";
import { ReactComponent as time } from "./time.svg";
import { ReactComponent as viewList } from "./view_list.svg";
import { ReactComponent as woman } from "./woman.svg";
import { ReactComponent as wounds } from "./wounds.svg";
import { ReactComponent as information } from "./information.svg";
import { ReactComponent as bolt } from "./bolt.svg";
import { ReactComponent as accept } from "./accept.svg";
import { ReactComponent as close } from "./close.svg";
import { ReactComponent as info } from "./info.svg";
import { ReactComponent as slack } from "./slackColor.svg";
import { ReactComponent as slackDark } from "./slackWhite.svg";
import { ReactComponent as eyeFilled } from "./eyeFilled.svg";
import { ReactComponent as eyeInvisibleFilled } from "./eyeInvisibleFilled.svg";
import { ReactComponent as drag2 } from "./drag2.svg";
import { ReactComponent as checkDark } from "./check-dark.svg";
import { ReactComponent as trash } from "./trash.svg";
import { ReactComponent as trashDark } from "./trashDark.svg";
import { ReactComponent as lockDark } from "./lockDark.svg";
import { ReactComponent as vivaEngage } from "./vivaEngage.svg";
import { ReactComponent as vivaEngageDark } from "./vivaEngageDark.svg";
import { ReactComponent as twitterStroke } from "./twitterStroke.svg";
import { ReactComponent as chipDark } from "./chip-dark.svg";
import { ReactComponent as wavesDark } from "./waves-dark.svg";
import { ReactComponent as chipLight } from "./chip-light.svg";
import { ReactComponent as wavesLight } from "./waves-light.svg";
import { ReactComponent as pdf } from "./pdf.svg";
import { ReactComponent as calendarDark } from "./calendar-dark.svg";
import { ReactComponent as clockDark } from "./clock-dark.svg";
import { ReactComponent as pcLight } from "./computerLight.svg";
import { ReactComponent as pcPlay } from "./computerPlay.svg";
import { ReactComponent as sharepoint } from "./sharepoint.svg";
import { ReactComponent as sharepointDark } from "./sharepointDark.svg";
import { ReactComponent as plus } from "./plus.svg";
// import { ReactComponent as zoom } from "./zoom.svg";
// import { ReactComponent as zoomDark } from "./zoomDark.svg";

export default function icon(name: string) {
  switch (name) {
    case "microsoftteams":
      return microsoftteams;
    case "microsoftteamsDark":
      return microsoftteamsDark;
    case "microsoftteamsSmall":
      return microsoftteamsSmall;
    // case "zoom":
    //   return zoom;
    // case "zoomDark":
    //   return zoomDark;
    case "yammer":
      return vivaEngage;
    case "yammerDark":
      return vivaEngageDark;
    case "yammerSmall":
      return vivaEngage;
    case "arrowLeftCustom":
      return arrowLeftCustom;
    case "arrowRightCustom":
      return arrowRightCustom;
    case "remoteLogin":
      return remoteLogin;
    case "unlock":
      return unlock;
    case "star":
      return star;
    case "verifyBadge":
      return verifyBadge;
    case "userError":
      return userError;
    case "transfer":
      return transfer;
    case "revert":
      return revert;
    case "notice":
      return notice;
    case "liveHours":
      return liveHours;
    case "hashtagError":
      return hashtagError;
    case "feature":
      return feature;
    case "deleteSign":
      return deleteSign;
    case "confirmation":
      return confirmation;
    case "arrowLeftLong":
      return arrowLeftLong;
    case "locationPinDark":
      return locationPinDark;
    case "locationPin":
      return locationPin;
    case "dropdownIcon":
      return dropdownIcon;
    case "filter":
      return filter;
    case "longArrowDown":
      return longArrowDown;
    case "refresh":
      return refresh;
    case "customize":
      return customize;
    case "team":
      return team;
    case "arrowRightLarge":
      return arrowRightLarge;
    case "editItem":
      return editItem;
    case "arrowUpLarge":
      return arrowUpLarge;
    case "radioOff":
      return radioOff;
    case "radioOn":
      return radioOn;
    case "afpColor":
      return afpColor;
    case "afpWhite":
      return afpWhite;
    case "reutersColor":
      return reutersColor;
    case "reutersWhite":
      return reutersWhite;
    case "skynewsarabiaColor":
      return skynewsarabiaWhite;
    case "skynewsarabiaWhite":
      return skynewsarabiaWhite;
    case "url":
      return url;
    case "url":
      return url;
    case "mail":
      return mail;
    case "stats":
      return stats;
    case "atSign":
      return atSign;
    case "autoApprove":
      return autoApprove;
    case "eye":
      return eye;
    case "hashtag":
      return hashtag;
    case "key":
      return key;
    case "leads":
      return leads;
    case "manualApprove":
      return manualApprove;
    case "onboard":
      return onboard;
    case "profile":
      return profile;
    case "settings":
      return settings;
    case "users":
      return users;
    case "appSpace":
      return appSpace;
    case "edit":
      return edit;
    case "error":
      return error;
    case "google":
      return google;
    case "workplaceDark":
      return workplaceDark;
    case "workplace":
      return workplace;
    case "webExDark":
      return webExDark;
    case "webExSmall":
      return webExSmall;
    case "workplaceSmall":
      return workplaceSmall;
    case "linkedInSmall":
      return linkedInSmall;
    case "instagrambusinessSmall":
      return instagrambusinessSmall;
    case "twitterSmall":
      return twitterSmall;
    case "facebookSmall":
      return facebookSmall;
    case "webEx":
      return webEx;
    case "twitter":
      return twitter;
    case "linkedIn":
      return linkedIn;
    case "instagrambusiness":
      return instagrambusiness;
    case "facebook":
      return facebook;
    case "changeColorRectangular":
      return changeColorRectangular;
    case "changeColor":
      return changeColor;
    case "screen":
      return screen;
    case "delete":
      return deleteIcon;
    case "facebookDark":
      return facebookDark;
    case "twitterDark":
      return twitterDark;
    case "instagrambusinessDark":
      return instagrambusinessDark;
    case "linkedInDark":
      return linkedInDark;
    case "billingOverview":
      return billingOverview;
    case "empty":
      return empty;
    case "checkGreen":
      return checkGreen;
    case "checkBlue":
      return checkBlue;
    case "play":
      return play;
    case "menu":
      return menu;
    case "longArrowLeft":
      return longArrowLeft;
    case "longArrowRight":
      return longArrowRight;
    case "createChannel":
      return createChannel;
    case "add":
      return add;
    case "addDark":
      return addDark;
    case "archive":
      return archive;
    case "arrowDown":
      return arrowDown;
    case "arrowLeft":
      return arrowLeft;
    case "arrowRight":
      return arrowRight;
    case "arrowUp":
      return arrowUp;
    case "bell":
      return bell;
    case "calendar":
      return calendar;
    case "card":
      return card;
    case "check":
      return check;
    case "cross":
      return cross;
    case "drag":
      return drag;
    case "euro":
      return euro;
    case "family":
      return family;
    case "female":
      return female;
    case "followUp":
      return followUp;
    case "graph":
      return graph;
    case "gridOn":
      return gridOn;
    case "groupActions":
      return groupActions;
    case "heartCalendar":
      return heartCalendar;
    case "heartTime":
      return heartTime;
    case "help":
      return help;
    case "home":
      return home;
    case "hospital":
      return hospital;
    case "label":
      return label;
    case "loading":
      return loading;
    case "male":
      return male;
    case "man":
      return man;
    case "medicalCheck":
      return medicalCheck;
    case "messages":
      return messages;
    case "nurse":
      return nurse;
    case "ok":
      return ok;
    case "phone":
      return phone;
    case "picture":
      return picture;
    case "pills":
      return pills;
    case "reply":
      return reply;
    case "room":
      return room;
    case "save":
      return save;
    case "search":
      return search;
    case "settings":
      return settings;
    case "sheets":
      return sheets;
    case "syncClouds":
      return syncClouds;
    case "sync":
      return sync;
    case "time":
      return time;
    case "viewList":
      return viewList;
    case "woman":
      return woman;
    case "wounds":
      return wounds;
    case "information":
      return information;
    case "bolt":
      return bolt;
    case "accept":
      return accept;
    case "close":
      return close;
    case "info":
      return info;
    case "slack":
      return slack;
    case "slackDark":
      return slackDark;
    case "eyeFilled":
      return eyeFilled;
    case "eyeInvisibleFilled":
      return eyeInvisibleFilled;
    case "drag2":
      return drag2;
    case "checkDark":
      return checkDark;
    case "trash":
      return trash;
    case "trashDark":
      return trashDark;
    case "lockDark":
      return lockDark;
    case "vivaEngage":
      return vivaEngage;
    case "vivaEngageDark":
      return vivaEngageDark;
    case "vivaEngageSmall":
      return vivaEngage;
    case "twitterStroke":
      return twitterStroke;
    case "computer":
      return computer;
    case "chip-dark":
      return chipDark;
    case "waves-dark":
      return wavesDark;
    case "chip-light":
      return chipLight;
    case "waves-light":
      return wavesLight;
    case "pdf":
      return pdf;
    case "calendar-dark":
      return calendarDark;
    case "clock-dark":
      return clockDark;
    case "computerLight":
      return pcLight;
    case "computerPlay":
      return pcPlay;
    case "sharepoint":
      return sharepoint;
    case "sharepointDark":
      return sharepointDark;
    case "plus":
      return plus;
    default:
      return help;
  }
}
