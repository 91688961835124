import React, { FunctionComponent, useEffect } from "react";
import {
  styled,
  Block,
  unit,
  Paper,
  ElementHeightBase,
  Icon,
  IconName,
  IPaperProps,
} from "../../../seenspire-library";
import { useToggle } from "react-use";

export interface IContainer extends IPaperProps {
  pulledUp?: boolean;
  fullHeight?: boolean;
  withHeader?: boolean;
  skipContentPadding?: boolean;
  attachedUp?: boolean;
  attachedDown?: boolean;
  attachRight?: boolean;
  collapsible?: boolean;
  initiallyCollapsed?: boolean;
  ref?: any;
  defaultPadding?: boolean;
  stayUniqWhenOpened?: boolean;
  noShadow?: boolean;
  onTabClick?: (value: boolean) => void;
  controlIsOpen?: boolean;
  minHeight?: string;
  maxHeight?: string;
  // ref?: ((instance: HTMLDivElement | null) => void) | React.RefObject<HTMLDivElement> | null | undefined
}

const ContainerStyled = styled(Paper).attrs({})<IContainer>`
  position: relative;
  width: 100%;
  /* max-width: 1200px; */
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  /* Pulls the container above the menu box */
  margin-top: ${({ pulledUp }) => pulledUp && unit(-30)};
  /* @TODO Fix calc function once menu is done ... 100 - header - menu padding */
  height: ${({ fullHeight }) =>
    fullHeight && "calc(100vh - 65px - 60px - 80px)"};
  border-radius: ${({ theme, attachedUp, attachedDown, attachRight }) => {
    switch (true) {
      case attachedUp:
        return ` 0px 0px ${unit(theme.borderRadius)} ${unit(
          theme.borderRadius
        )}`;

      case attachedDown:
        return `${unit(theme.borderRadius)} ${unit(
          theme.borderRadius
        )} 0px 0px`;

      case attachRight:
        return `${unit(theme.borderRadius)} 0px 0px ${unit(
          theme.borderRadius
        )}`;

      default:
        return `${unit(theme.borderRadius)}`;
    }
  }};
  ${({ minHeight }) => {
    if (minHeight) {
      return `min-height: ${minHeight};`;
    }
  }};
  ${({ maxHeight }) => {
    if (maxHeight) {
      return `max-height: ${maxHeight};`;
    }
  }};
`;

const ContainerHeaderWrapper = styled(Block)`
  width: 100%;
  align-items: center;
  ${ElementHeightBase}
`;

const ContainerHeaderTitle = styled(Block)`
  font-size: 28px;
`;
const ContainerHeaderDescription = styled(Block)`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 12px;
`;

const ContainerHeader: FunctionComponent<{
  icon?: IconName;
  title?: any;
  description?: any;
}> = ({ icon, title, description }) => {
  return (
    <Block display="flex" alignItems="center">
      {icon && <Icon name={icon} iconSize="larger" color="cta" />}
      <Block padding={{ all: 10 }}>
        <ContainerHeaderTitle fontWeight="bold" fontSize="larger">
          {title}
        </ContainerHeaderTitle>
        <ContainerHeaderDescription>{description}</ContainerHeaderDescription>
      </Block>
    </Block>
  );
};
const Container: FunctionComponent<IContainer> = ({
  children,
  withHeader,
  skipContentPadding,
  collapsible,
  initiallyCollapsed,
  defaultPadding,
  stayUniqWhenOpened,
  onTabClick,
  controlIsOpen,
  noShadow,
  ..._props
}) => {
  const childrenArray = React.Children.toArray(children);
  const [header, ...content] = childrenArray;
  const props: IPaperProps = {
    bg: "white",
    display: "grid",
    overflow: _props.overflow || "hidden",
    scrollY: _props.scrollY || false,
    padding: skipContentPadding
      ? {}
      : defaultPadding
      ? { top: 55, right: 68, bottom: 55, left: 68 }
      : { all: 20 },
    gridGap:
      typeof _props.gridGap === "number"
        ? _props.gridGap
        : collapsible
        ? 0
        : 20,
    ...(!noShadow && { shadow: "dark" }),
    ..._props,
  };

  const [opened, open] = useToggle(!!(collapsible && !initiallyCollapsed));

  // Control the opne state
  useEffect(() => {
    if (controlIsOpen === undefined) {
      return;
    }

    open(controlIsOpen);
  }, [controlIsOpen, open]);

  return (
    <ContainerStyled {...props}>
      {withHeader ? (
        <>
          <ContainerHeaderWrapper
            display={collapsible ? "grid" : "flex"}
            gridTemplate={{ columns: "1fr 36px" }}
            gridGap={10}
            cursor={collapsible ? "pointer" : "unset"}
            onClick={() => {
              if (collapsible) {
                open();
                if (onTabClick) {
                  onTabClick(!opened);
                }
              }
            }}
            padding={
              skipContentPadding
                ? { all: 20, bottom: collapsible ? 20 : 0 }
                : {}
            }
          >
            {header}

            {collapsible && <Icon name={opened ? "arrowUp" : "arrowDown"} />}
          </ContainerHeaderWrapper>
          {collapsible ? opened && content : content}
        </>
      ) : (
        <>{children}</>
      )}
    </ContainerStyled>
  );
};

export { Container, ContainerHeader };
