import { ITextProps, Text } from "../Text/Text";
import {
  IAlignment,
  IShadow,
  IFlex,
  IBorderRadius,
  IBackgroundColor,
  IResponsiveAt,
  ICursor,
  IZIndex,
} from "../../styled-components/interface";
import { styled, css } from "../../styled-components";
import {
  Shadow,
  Alignment,
  Flex,
  Spacing,
  BackgroundColor,
  BorderRadius,
  ResponsiveAt,
  Cursor,
  ZIndex,
} from "../styles";

interface IBlockProps
  extends ITextProps,
    IAlignment,
    IShadow,
    IFlex,
    IBorderRadius,
    IBackgroundColor,
    IResponsiveAt,
    ICursor,
    IZIndex {
  className?: string;
  gridTemplateColumns?: string;
}

const BlockStyles = css`
  ${BorderRadius};
  ${BackgroundColor};
  ${Spacing};
  ${Flex};
  ${Alignment};
  ${Shadow};
  ${ResponsiveAt};
  ${Cursor};
  ${ZIndex};
`;

const Block = styled(Text).attrs({})<IBlockProps>`
  ${BlockStyles};
`;

export { Block, IBlockProps, BlockStyles };
